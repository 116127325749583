"use strict";

// tohle nahraje i soubory, ktere nejsou primo linkovane z entrypointu
import "./img/*.{png,jpg,jpeg,gif,svg}";

// UIkit
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";

// loads the Icon plugin
UIkit.use(Icons);

// import stylu
import "./../sass/index.scss";

//menu
//MENU OPEN

document.addEventListener("DOMContentLoaded", function () {

  const isMobile = () => {
    return window.innerWidth < 760 ? true : false;
  };
 

  //if (isMobile()) console.log('je to mobil');

    const menuTriggerOpen = document.getElementById("topMenu__open");
    const menuHolder = document.getElementById("topMenu__collapse");
    const menuTriggerClose = document.getElementById("topMenu__close");
    const menuCloseAnimation = document.getElementById("topMenu__animClose");

    if (menuTriggerOpen && menuHolder && menuTriggerClose) {
      menuTriggerOpen.addEventListener("click", (event) => { submenuAction('open', event) }, false);
      menuTriggerClose.addEventListener("click", (event) => { submenuAction('close', event) }, false);

    }

    const submenuAction = (action, event) => {
		console.log('kliknuto');
        if (event) event.preventDefault();
        switch(action) {
            case "open":
              menuHolder.classList.toggle("topMenu__collapse__open");
              menuCloseAnimation.classList.add("topMenu__closeAnimationRunTo");
                break;
            case "close":
              menuHolder.classList.remove("topMenu__collapse__open");
              menuCloseAnimation.classList.remove("topMenu__closeAnimationRunTo");
                break;
        }
     };

});




window.addEventListener("scroll", (event) => {
	paralax();
});
window.addEventListener("load", (event) => {
	paralax();
});

function paralax() {
	var item = document.getElementsByClassName("paraX");
	for (var i = 0; i < item.length; i++) {
		if (!item.item(i).classList.contains("paraAnim")) {
			var y = window.pageYOffset - item.item(i).offsetTop + window.innerHeight;
			var breakY = 100;
			if (window.innerHeight < 650) breakY = 50;
			if (y > breakY) {
				item.item(i).classList.add("paraAnim");
			}
		}
	}
}
